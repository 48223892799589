import { useEffect } from "preact/hooks";
/**
 * Universal handling of event listeners with useEffect to use it in preact codebase.
 * Listener will be added when component mounts.
 * Listener will be removed when component unmounts.
 *
 * @param event {String} event name.
 * @param callback {Function} Function to call.
 */
export default function (event, callback) {
    useEffect(() => {
        document.addEventListener(event, callback);
        return () => {
            document.removeEventListener(event, callback);
        };
    }, []);
}
