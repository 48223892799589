/**
 * Copy given text to clipboard.
 *
 * @param {string} text Text to copy.
 * @param {function} onCopy Optional function to trigger on successful copy.
 * @returns {void}
 */
export default function (text, onCopy) {
  navigator.clipboard.writeText(text).then(() => {
    console.debug("copyToClipboard: ", "Text copied to clipboard");
    onCopy && onCopy();
  });
}
