import StorageService from "../../services/StorageService";
// https://developers.google.com/analytics/devguides/collection/gtagjs
// Remove null, undefined and empty object keys recursively
const cleanEmpty = (obj) => Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === "object" ? cleanEmpty(v) : v])
    .reduce((a, [k, v]) => v === null ||
    v === undefined ||
    (typeof v === "object" && Object.keys(v).length === 0)
    ? a
    : ((a[k] = v), a), {});
// Map AnalyticsV3 (we use the old format) params to V4 params
const paramMapping = (params) => {
    return {
        campaign: {
            name: params.campaignName,
            source: params.campaignSource,
            medium: params.campaignMedium,
            keyword: params.campaignKeyword,
            content: params.campaignContent,
            id: params.campaignId,
        },
        app_name: params.appName,
        app_id: params.appId,
        app_version: params.appVersion,
        app_installer_id: params.appInstallerId,
        referrer: params.referrer,
        data_source: params.dataSource,
    };
};
function createTracker(key, measurementId, config, extraParams) {
    const triggerEvent = (name, params = {}) => {
        gtag("event", name, Object.assign({
            send_to: measurementId,
            themeName: config.themeName,
            siteName: config.siteName,
            country: config.country,
            siteUrl: window.location.origin,
        }, params));
    };
    return {
        init: function (clientId) {
            console.log(`Initializing analytics (gtag v4) ${measurementId} with clientId ${clientId}`);
            // Most of those params are undocumented, but appear in gtag source code
            const p = paramMapping(extraParams);
            // Dimension mapping
            const dimensions = {
                dimension1: "themeName",
                dimension2: "siteName",
                dimension3: "siteUrl",
                dimension4: "country",
            };
            gtag("js", new Date());
            gtag("config", measurementId, cleanEmpty(Object.assign(p, {
                send_page_view: false,
                allow_enhanced_conversions: true,
                client_id: clientId,
                custom_map: dimensions,
                cookie_flags: "SameSite=None; Secure",
                // Set dimension values to send the data with every event automatically sent by GTAG, not just those triggered manually
                country: config.country,
                themeName: config.themeName,
                siteName: config.siteName,
            })));
            const user = StorageService.getJSONItem("client_contactDetails");
            if (user) {
                gtag("set", "user_data", {
                    email: user.email,
                    phone_number: user.phoneNumber.replace(/ /g, ""), // Remove spaces according to E.164 format https://en.wikipedia.org/wiki/E.164
                });
            }
        },
        pageView: function (viewName) {
            if (key == "restaumatic") {
                triggerEvent("page_view", { page_title: viewName });
            }
            else {
                triggerEvent("page_view");
            }
        },
        onError: function (message) {
            triggerEvent("exception", { description: message });
        },
        customEvent: function (item) {
            triggerEvent(item.action, {
                event_category: item.category || item.siteSlug,
                event_label: item.optLabel,
                event_value: item.optValue,
            });
        },
        standardEvent: function (event) {
            const e = event["analytics_v4"];
            if (e) {
                triggerEvent(e.name, e.value);
                if (key == "restaumatic" &&
                    e.name == "purchase" &&
                    config.adwordsAnalyticsV4) {
                    const data = e.value;
                    triggerEvent("conversion", {
                        send_to: config.adwordsAnalyticsV4,
                        value: data.value,
                        currency: data.currency,
                        transaction_id: data.transaction_id,
                    });
                }
            }
        },
    };
}
export default { createTracker };
