import { h } from "preact";
function UpcomingHolidays(props) {
    const { className, upcomingHolidays } = props;
    return (h("div", { class: `m-restaurant-hours ${className}` }, upcomingHolidays.map((holiday) => (h(HolidayRow, { data: holiday })))));
}
function HolidayRow(props) {
    const { duration, description, info } = props.data;
    return (h("div", null,
        h("div", { className: "m-restaurant-hours__item" },
            h("span", { className: "m-restaurant-hours__header" },
                duration,
                h("span", { dangerouslySetInnerHTML: { __html: "&nbsp;" } })),
            h("span", { className: "m-restaurant-hours__data-wrapper" }, description),
            h("span", { className: "clearfix" })),
        h("span", { className: "m-restaurant-hours__info" }, info)));
}
export default UpcomingHolidays;
