import AnalyticsV4 from "./Tracking/AnalyticsV4";
import FacebookPixel from "./Tracking/FacebookPixel";
import Ahoy from "./Tracking/Ahoy";
import Device from "./Device";
// TODO: Subtle change: getExtraParams are now called during tracker creation instead of after startTracking
// (previously initAnalytics) is called, we should verify that they are available
function getExtraParams() {
    /* Our IOS app and external apps (not belonging to us) */
    if ("RestaumaticAnalytics" in window) {
        return JSON.parse(RestaumaticAnalytics.getExtraParams());
    } /* Our android mobile apps */
    else if (Device.isMobileApp()) {
        return { campaignSource: "android_app", campaignMedium: "android_app" };
    }
    else {
        return {};
    }
}
// Initialize trackers based on the cookies accepted by the client and tracker configuration for a site
function createTrackers(cookieLevels, config) {
    const extraParams = getExtraParams();
    const isAnalyticsAllowed = cookieLevels.includes("analytics");
    const isMarketingAllowed = cookieLevels.includes("marketing");
    const trackers = [];
    if (isAnalyticsAllowed) {
        if (config.ahoyEnabled) {
            trackers.push(Ahoy.createTracker(config));
        }
        Object.entries(config.analyticsV4).forEach(function ([k, v]) {
            trackers.push(AnalyticsV4.createTracker(k, v, config, extraParams));
        });
    }
    if (isMarketingAllowed && config.facebookPixelId) {
        trackers.push(FacebookPixel.createTracker(config.facebookPixelId, config));
    }
    return trackers;
}
// Attach DOM event listeners for old data API events. I think this could be deprecated now, with one exception:
// PhoneClick events (measuring how often someone has clicked on the phone to reveal it)
function attachDomListeners() {
    document.addEventListener("click", function (e) {
        // It can be not only `HTMLElement` but also others, e.g. `SVGElement`, so `Element` is a safe check.
        if (!(e.target instanceof Element))
            return;
        const el = e.target;
        let eventEl;
        if (el.matches("[data-ga-action]")) {
            eventEl = el;
        }
        else {
            const parent = el.closest("[data-ga-action]");
            if (!parent)
                return;
            eventEl = parent;
        }
        Skubacz.tracking({
            tag: "TrackCustomEvent",
            event: {
                action: eventEl.dataset.gaAction,
                optLabel: eventEl.dataset.gaLabel,
                optValue: eventEl.dataset.gaValue,
            },
        });
    });
    // We might be missing some errors in our logs because this is initialized quite late
    var _onError = window.onerror;
    window.onerror = function (message, source, lineno, colno, exception) {
        // NB: we now send it to all trackers (even non restaumatic ones)
        Skubacz.tracking({ tag: "Error", message: message });
        if (_onError) {
            _onError.apply(window, [message, source, lineno, colno, exception]);
        }
    };
}
function init() {
    const config = Skubacz.configuration.tracking;
    // Internal state for synchronization
    const trackers = [];
    let clientId = null, activeMenuInitialized = false, initialPageView = true, consentsAccepted = false, startedTracking = false, 
    // Queue for events that have to be handled after we have _startedTracking.
    // Why do we need another queue, you might ask? Why not `Skubacz.tracking.subscribe` only when tracking is started?
    // The reason is that to `startTracking` we need to react to `ConsentsAccepted` event, so we have to `subscribe` earlier.
    queue = [];
    attachDomListeners();
    function startTracking() {
        trackers.forEach((tracker) => {
            tracker.init(clientId);
            if (initialPageView) {
                tracker.pageView(config.viewName);
            }
        });
        startedTracking = true;
        // Consume non-initialization events
        processQueue(processEvent, queue);
    }
    function handleIfStarted(event, f) {
        if (startedTracking) {
            f(event);
        }
        else {
            queue.push(event);
        }
    }
    function processEvent(event) {
        switch (event.tag) {
            case "InitActiveMenu":
                clientId = event.clientId;
                activeMenuInitialized = true;
                if (consentsAccepted) {
                    startTracking();
                }
                break;
            case "ConsentsAccepted":
                initialPageView = event.initialPageView;
                consentsAccepted = true;
                trackers.push(...createTrackers(event.cookieLevels, config));
                if (config.activeMenu === false || activeMenuInitialized) {
                    startTracking();
                }
                break;
            case "TrackNamedEvent":
                handleIfStarted(event, (event) => {
                    trackers.forEach((tracker) => {
                        tracker.standardEvent(event.event);
                    });
                });
                break;
            case "TrackPageView":
                handleIfStarted(event, (event) => {
                    trackers.forEach((tracker) => {
                        tracker.pageView(event.viewName);
                    });
                });
                break;
            case "TrackCustomEvent":
                handleIfStarted(event, (event) => {
                    trackers.forEach((tracker) => {
                        tracker.customEvent(event.event);
                    });
                });
                break;
            case "Error":
                handleIfStarted(event, (event) => {
                    trackers.forEach((tracker) => {
                        tracker.onError(event.message);
                    });
                });
                break;
        }
    }
    return {
        processEvent,
    };
}
function processQueue(processEvent, queue) {
    while (queue.length > 0) {
        let event = queue.shift();
        if (event) {
            processEvent(event);
        }
    }
}
// Init trackers and start processing from the Skubacz.tracking queue
function initAndStartProcessing() {
    const tracker = init();
    // Redefine the `tracking` function to process events directly
    Skubacz.tracking.subscribe(function (e) {
        tracker.processEvent(e);
    });
    processQueue(tracker.processEvent, Skubacz.tracking.q);
    // Process any events that have already been queued
    return tracker;
}
export default { init: initAndStartProcessing };
