import { h } from "preact";
import { observer } from "mobx-react";
import { t, translate } from "restaumatic-client";
function LocateMeButton(props) {
    const { onLocateMe, isLoading = false, geolocationError } = props;
    return (h("div", { class: "form-group m-form__group u-full-width" },
        h("button", { onClick: onLocateMe, type: "button", class: "btn btn-link u-p0 u-box-shadow-none", "data-field-id": "fulfillmentMethod.deliveryAddress.locateMe", disabled: isLoading },
            h("span", { "aria-hidden": "true", class: "icon-target u-font-size-120p" }),
            translate(t.delivery_method.locate_me)),
        geolocationError && h("div", { class: "text-danger" }, geolocationError)));
}
export default observer(LocateMeButton);
