import hypernova, { serialize, load } from "hypernova";
import { h, render } from "preact";
import renderToString from "preact-render-to-string";
import PageContext from "../components/PageContext";

// A clone of https://github.com/ara-framework/hypernova-preact/blob/master/src/index.js which wraps the resulting
// component in PageContext
export const renderPreact = (name, component) =>
  hypernova({
    server() {
      return (props) => {
        let ctx = props["__context__"];
        // We can't delete __context__ from props on the server, because it won't be available
        // when hydrating the client
        global.I18n.locale = ctx.locale;
        const html = renderToString(
          h(PageContext.Provider, { value: ctx }, [h(component, props)])
        );
        // revert back to default locale
        global.I18n.locale = "en";
        return serialize(name, html, props);
      };
    },

    client() {
      const payloads = load(name);

      if (payloads) {
        payloads.forEach((payload) => {
          const { node, data } = payload;
          let ctx = data["__context__"];
          delete data["__context__"];
          const element = h(PageContext.Provider, { value: ctx }, [
            h(component, data),
          ]);
          render(element, node);
        });
      }

      return component;
    },
  });
