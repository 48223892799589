import { createContext } from "preact";
const initialValue = {
    get locale() {
        return fail();
    },
    get defaultLocale() {
        return fail();
    },
    get currency() {
        return fail();
    },
    get country() {
        return fail();
    },
    get siteId() {
        return fail();
    },
    get accountId() {
        return fail();
    },
};
function fail() {
    throw new Error("Attempting to use PageContext without providing one");
}
const ctx = createContext(initialValue);
export default ctx;
