/**
 * Dispatch an action to another type.
 *
 * @param dispatch
 * @param tag
 * @returns {Function}
 */
export default function (dispatch, tag) {
  return function (action) {
    return dispatch({
      type: tag,
      payload: action,
    });
  };
}
