import Cmd from "../utils/Cmd";
import * as GroupToggle from "../components/GroupToggle";
export default {
    init: (isActive = "") => [isActive, Cmd.none],
    update: (state, action) => {
        switch (action.type) {
            case "CHANGE_ITEM":
                return [action.target, Cmd.none];
        }
        return [state, Cmd.none];
    },
    view: (element, state, dispatch) => {
        element.forEach((el) => {
            const t = GroupToggle.utils.getItemFromAnchor(el.getAttribute("id") || "");
            if (t === state) {
                el.classList.add("is-active");
            }
            else {
                el.classList.remove("is-active");
            }
        });
    },
};
