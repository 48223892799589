// https://developers.facebook.com/docs/facebook-pixel/reference
function createTracker(pixelId, config) {
  return {
    init: function (clientId) {
      fbq("init", pixelId);
    },
    customEvent: function (item) {
      fbq("trackCustom", item.action, {
        label: item.optLabel,
        value: item.optValue,
      });
    },
    standardEvent: function (event) {
      const platformEvent = event["facebook_v2"];
      if (platformEvent) {
        fbq("track", platformEvent.name, platformEvent.value);
      }
    },
    pageView: function (viewName) {
      fbq("track", "PageView");
    },
    onError: function (message) {
      fbq("trackCustom", "JSError", { message: message });
    },
  };
}

export default { createTracker };
