import * as CookieConsent from "vanilla-cookieconsent";
import Tracking from "../utils/Tracking";
import Device from "../utils/Device";
import { t, translate } from "restaumatic-client";
// Define this object for external usage of Cookie Consent instance
window.CC = CookieConsent;
const isAmInIframe = Device.isInIframe() && Skubacz.configuration.theme_name === "active_menu";
// Config docs: https://cookieconsent.orestbida.com/reference/configuration-reference.html
function getCookieConfig() {
    const privacy_policy_link = `<a href="/privacy-policy">${translate(t.cookie_consent.consent_modal.privacy_policy)}</a>`;
    return {
        // Bump `revision` if you want to show consent widget again for all users.
        revision: 3,
        autoShow: true,
        hideFromBots: Skubacz.configuration.environment === "systemtest" ? false : true,
        manageScriptTags: true,
        // disablePageInteraction: false,
        cookie: {
            useLocalStorage: isAmInIframe,
        },
        guiOptions: {
            consentModal: {
                layout: "box",
                equalWeightButtons: true,
                flipButtons: false,
            },
            preferencesModal: {
                equalWeightButtons: true,
                flipButtons: false,
            },
        },
        // onChange: ({cookie, changedCategories, changedPreferences}) => {}
        onConsent: ({ cookie }) => {
            const tracking = Tracking.init();
            const layer = window;
            const categories = cookie.categories;
            layer.gtag("consent", "update", {
                ad_storage: categories.includes("marketing") ? "granted" : "denied",
                ad_user_data: categories.includes("marketing") ? "granted" : "denied",
                ad_personalization: categories.includes("marketing")
                    ? "granted"
                    : "denied",
                analytics_storage: categories.includes("analytics")
                    ? "granted"
                    : "denied",
            });
            // HACK To make GTM accept the new consents
            layer.dataLayer.push({ event: "gtm.init_consent" });
            Skubacz.tracking({
                tag: "ConsentsAccepted",
                initialPageView: true,
                cookieLevels: categories,
            });
        },
        categories: {
            // necessary: {
            //   enabled: true,
            //   readOnly: true
            // },
            functionality: {
                enabled: true,
                readOnly: true,
                // autoClear: {} Clear cookies defined here when the user rejects the cookie category.
            },
            analytics: {
                enabled: false,
                readOnly: false,
            },
            marketing: {
                enabled: false,
                readOnly: false,
            },
        },
        language: {
            default: "en",
            translations: {
                en: {
                    consentModal: {
                        title: translate(t.cookie_consent.consent_modal.title),
                        description: translate(t.cookie_consent.consent_modal.description, {
                            privacy_policy_link,
                        }),
                        acceptAllBtn: translate(t.cookie_consent.consent_modal.accept_all),
                        acceptNecessaryBtn: translate(t.cookie_consent.consent_modal.accept_necessary),
                        showPreferencesBtn: translate(t.cookie_consent.consent_modal.settings),
                    },
                    preferencesModal: {
                        title: translate(t.cookie_consent.settings_modal.title),
                        acceptAllBtn: translate(t.cookie_consent.consent_modal.accept_all),
                        acceptNecessaryBtn: translate(t.cookie_consent.consent_modal.accept_necessary),
                        savePreferencesBtn: translate(t.cookie_consent.settings_modal.save_settings_btn),
                        closeIconLabel: "Close modal",
                        sections: [
                            {
                                title: translate(t.cookie_consent.privacy.title),
                                description: translate(t.cookie_consent.privacy.description),
                            },
                            {
                                title: translate(t.cookie_consent.functional_cookies.title),
                                description: translate(t.cookie_consent.functional_cookies.description),
                                linkedCategory: "functionality",
                                cookieTable: {
                                    headers: {
                                        name: translate(t.cookie_consent.cookie_table.headers.name),
                                        source: translate(t.cookie_consent.cookie_table.headers.source),
                                        description: translate(t.cookie_consent.cookie_table.headers.description),
                                    },
                                    body: [
                                        {
                                            name: "cc_cookie",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.consent),
                                        },
                                        {
                                            name: "currentRestaurant",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.current_restaurant),
                                        },
                                        {
                                            name: "client_cart_[restaurant]",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_cart),
                                        },
                                        {
                                            name: "client_previousOrder_[restaurant]",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_cart_previous),
                                        },
                                        {
                                            name: "client_fulfillmentMethod",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_fulfillment_method),
                                        },
                                        {
                                            name: "client_fulfillmentTime",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_fulfillment_time),
                                        },
                                        {
                                            name: "client_street_address",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_street_address),
                                        },
                                        {
                                            name: "client_apartment_info",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_apartment),
                                        },
                                        {
                                            name: "client_paymentMedium",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_payment),
                                        },
                                        {
                                            name: "client_contactDetails",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_contact),
                                        },
                                        {
                                            name: "client_contactDetails_qr_waiter",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_contact_qr),
                                        },
                                        {
                                            name: "client_invoiceDetails",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_invoice),
                                        },
                                        {
                                            name: "client_storageFormatVersion",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_format),
                                        },
                                        {
                                            name: "client_consents",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_consents),
                                        },
                                        {
                                            name: "client_orderItemListTracking",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.client_order_item_tracking),
                                        },
                                        {
                                            name: "reservation:phone",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.reservation_phone),
                                        },
                                        {
                                            name: "reservation:email",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.reservation_email),
                                        },
                                        {
                                            name: "reservation:fullName",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.reservation_name),
                                        },
                                        {
                                            name: "reservation:info",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.reservation_info),
                                        },
                                        {
                                            name: "reservation:numPeople",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.reservation_num),
                                        },
                                        {
                                            name: "akaalb_Secure",
                                            source: "przelewy24.pl",
                                            description: translate(t.cookie_consent.cookie_table.przelewy24),
                                        },
                                        {
                                            name: "feature_[feature_name]",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.feature),
                                        },
                                        {
                                            name: "^notification-",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.notification),
                                        },
                                        {
                                            name: "customerSurvey",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.survey),
                                        },
                                        {
                                            name: "survey_hide_[restaurant]",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.survey_hide),
                                        },
                                        {
                                            name: "tracking_[event_key]",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.tracking),
                                        },
                                        {
                                            name: "fieldTestExperiments",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.field_tests),
                                        },
                                        {
                                            name: "test",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.test),
                                        },
                                    ],
                                },
                            },
                            {
                                title: translate(t.cookie_consent.analytics_cookies.title),
                                description: translate(t.cookie_consent.analytics_cookies.description),
                                linkedCategory: "analytics",
                                cookieTable: {
                                    headers: {
                                        name: translate(t.cookie_consent.cookie_table.headers.name),
                                        source: translate(t.cookie_consent.cookie_table.headers.source),
                                        description: translate(t.cookie_consent.cookie_table.headers.description),
                                    },
                                    body: [
                                        {
                                            name: "^_ga",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_analytics),
                                        },
                                        {
                                            name: "^_gid",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_analytics),
                                        },
                                        {
                                            name: "^_utm",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_analytics),
                                        },
                                        {
                                            name: "^_hjSession_",
                                            source: "hotjar.com",
                                            description: translate(t.cookie_consent.cookie_table.hotjar),
                                        },
                                        {
                                            name: "^_hjSessionUser_",
                                            source: "hotjar.com",
                                            description: translate(t.cookie_consent.cookie_table.hotjar),
                                        },
                                        {
                                            name: "^ahoy",
                                            source: "restaumatic.com",
                                            description: translate(t.cookie_consent.cookie_table.ahoy),
                                        },
                                        // TODO: Add specific key for "Microsoft Clarity" desc value
                                        {
                                            name: "_clck",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "_clsk",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "CLID",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "ANONCHK",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "MR",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "MUID",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "SM",
                                            source: "clarity.ms",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                        {
                                            name: "SRM_B",
                                            source: "bing.com",
                                            description: translate(t.cookie_consent.cookie_table.hotjar).replace("HotJar", "Microsoft Clarity"),
                                        },
                                    ],
                                },
                            },
                            {
                                title: translate(t.cookie_consent.marketing_cookies.title),
                                description: translate(t.cookie_consent.marketing_cookies.description),
                                linkedCategory: "marketing",
                                // Google cookie list: https://business.safety.google/adscookies/
                                cookieTable: {
                                    headers: {
                                        name: translate(t.cookie_consent.cookie_table.headers.name),
                                        source: translate(t.cookie_consent.cookie_table.headers.source),
                                        description: translate(t.cookie_consent.cookie_table.headers.description),
                                    },
                                    body: [
                                        {
                                            name: "^_fbp",
                                            source: "facebook.com",
                                            description: translate(t.cookie_consent.cookie_table.facebook_pixel),
                                        },
                                        {
                                            name: "lastExternalReferrer",
                                            source: "facebook.com",
                                            description: translate(t.cookie_consent.cookie_table.facebook_pixel),
                                        },
                                        {
                                            name: "lastExternalReferrerTime",
                                            source: "facebook.com",
                                            description: translate(t.cookie_consent.cookie_table.facebook_pixel),
                                        },
                                        {
                                            name: "^_gcl",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_ads),
                                        },
                                        {
                                            name: "^_gac",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_ads),
                                        },
                                        {
                                            name: "^ad_",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_ads),
                                        },
                                        {
                                            name: "CONVERSION",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_ads),
                                        },
                                        {
                                            name: "^_opt",
                                            source: "google.com",
                                            description: translate(t.cookie_consent.cookie_table.google_optimize),
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            },
        },
    };
}
function initCookieConsent() {
    if (Device.isMobileApp()) {
        // Android mobile apps don't require cookie acceptance, because it is done when installing the app
        // IOS apps don't import this file
        window.addEventListener("cc:onModalShow", () => {
            CookieConsent.hide();
            CookieConsent.acceptCategory("all");
        });
    }
    if (isAmInIframe) {
        // Fix focus on Cookie Consent box in AM iframe
        const hiddenClass = "hidden";
        window.addEventListener("cc:onModalReady", (e) => {
            const boxEl = e.detail.modal;
            const boxName = e.detail.modalName;
            if (boxEl && boxName === "consentModal") {
                boxEl.classList.add(hiddenClass);
            }
        });
        window.addEventListener("cc:onModalShow", () => {
            const hiddenBoxEl = document.querySelector(`.cm.${hiddenClass}`);
            // Wait for library transition / focus
            setTimeout(() => {
                hiddenBoxEl === null || hiddenBoxEl === void 0 ? void 0 : hiddenBoxEl.classList.remove(hiddenClass);
            }, 500);
        });
    }
    CookieConsent.run(getCookieConfig());
    // Accept cookies for E2E environment
    // TODO: Remove this & Use window.CC object directly in E2E tests
    if (Skubacz.configuration.environment === "systemtest") {
        if (!CookieConsent.validConsent()) {
            window.__addTask__("ccModal:show");
        }
        // hide the modal on first show
        window.addEventListener("cc:onModalShow", () => {
            CookieConsent.acceptCategory("all");
            CookieConsent.hide();
        }, { once: true });
        document
            .querySelectorAll('[data-field-id="showPreferences"]')
            .forEach((el) => {
            el.addEventListener("click", () => {
                window.__addTask__("ccModal:show");
            });
        });
        window.addEventListener("cc:onModalShow", () => {
            window.__doneTask__("ccModal:show");
        });
    }
}
/**
 * Cookie consent component.
 */
export default {
    init: initCookieConsent,
};
