const cartSelector = "#cart";
const cartFabSelector = ".js-cart-button.m-floating-action__btn";
/**
 * Initialize FAB animation.
 *
 * @param {HTMLElement} cartFab The cart FAB element.
 */
function initFabAnimation(cartFab) {
    const animationClassName = cartFab.classList.contains("m-floating-action__btn--icon-only")
        ? "bounce"
        : "swing";
    cartFab.classList.add("animated");
    cartFab.classList.remove("fade");
    cartFab.addEventListener("animationend", () => cartFab.classList.remove(animationClassName));
    // Modal is created dynamically in PS/JS, so handle it using event delegation.
    document.addEventListener("hidden.bs.modal", (e) => {
        if ("#" + e.target.id === cartSelector) {
            cartFab.classList.add(animationClassName);
        }
    });
}
/**
 * Show attention animation to indicate the cart button to the user.
 */
export default function () {
    const cartFab = document.querySelector(cartFabSelector);
    // Special animations only applies to themes with the cart button as floating action button.
    if (cartFab) {
        initFabAnimation(cartFab);
    }
}
