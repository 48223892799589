import "../../lib/ahoy";

function createTracker(config) {
  ahoy.eventExtra.siteId = config.siteId;
  return {
    init: function (_clientId) {
      ahoy.init();
    },
    customEvent: function (item) {
      ahoy.track(item.action, { label: item.optLabel, value: item.optValue });
    },
    standardEvent: function (event) {
      const platformEvent = event["restaumatic"];
      ahoy.track(platformEvent.name, platformEvent.value);
    },
    pageView: function (_viewName) {
      ahoy.trackView();
    },
    onError: function (message) {
      ahoy.track("JSError", { message: message });
    },
  };
}

export default { createTracker };
