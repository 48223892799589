/** @jsx h */
import { h, Fragment } from "preact";
import Alert from "./Alert";
import { useState } from "preact/hooks";
import { renderPreact } from "../lib/render-preact";
/* To use this component with SSR:
   - Add to app.js (can be some other application JS file), to run the client side of the component
    ```js
      import "./components/SomeComponent";
    ```
   - export the component in ssr.js, so it is available from hypernova for SSR
   - use in liquid: {% react_component "SomeComponent", name: "Test name!", secondName: "Ala!", config: some_component_config %}
 */
function SomeComponent(props) {
    var _a, _b, _c;
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        setClicked(true);
    };
    return (h(Fragment, null,
        h("p", { style: { "font-size": ((_b = (_a = props.config) === null || _a === void 0 ? void 0 : _a.fontSize) !== null && _b !== void 0 ? _b : 12) + "px" } },
            "Hello",
            " ",
            ((_c = props.config) === null || _c === void 0 ? void 0 : _c.shout)
                ? props.secondName.toUpperCase()
                : props.secondName),
        h(Alert, { message: props.name, type: "info" }),
        h("button", { class: "btn btn-info", onClick: handleClick }, clicked ? "Clicked" : "No clicks yet")));
}
// renderPreact will behave differently when called on the server (rendering the static html) and on the client,
// attaching to the DOM rendered on the server
// This is only required for top-level components (those you render via `react_component` tag)
export default renderPreact("SomeComponent", SomeComponent);
