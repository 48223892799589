/**
 * Calls a function if element has not been initialized.
 *
 * @param element {Element} DOM element.
 * @param initializer {Function} Function to call.
 * @param attribute {String} Name of attribute to set
 */

export default function (element, initializer, attribute = "data-initialized") {
  if (!element) {
    throw new Error("Provide an element");
  } else if (!(element instanceof Element)) {
    console.error("Provide correct DOM element");
  } else if (!element.getAttribute(attribute)) {
    initializer.call(element);
    element.setAttribute(attribute, true);
  }
}
