import Cmd from "../utils/Cmd";
import onInit from "../utils/onInit";
const responsiveNav = document.getElementById("responsive-nav");
const defaultOptions = {
    activeClassName: "active",
    dropdownSelector: ".dropdown",
    dropdownToggleSelector: ".dropdown-toggle",
};
/**
 * Initiate state of Navigation.
 */
function initNavigationState(state) {
    return (dispatch) => {
        dispatch({ type: "ACTIVATE_ITEM", target: location.pathname });
    };
}
/**
 * Mark active navigation links.
 */
function markActiveNavLinks(navigation, state, options) {
    navigation.querySelectorAll(`[href="${state.pathname}"]`).forEach((item) => {
        const dropdown = item.closest(options.dropdownSelector);
        const dropdownToggle = dropdown === null || dropdown === void 0 ? void 0 : dropdown.querySelector(options.dropdownToggleSelector);
        item.classList.add(options.activeClassName);
        dropdownToggle === null || dropdownToggle === void 0 ? void 0 : dropdownToggle.classList.add(options.activeClassName);
    });
}
/**
 * Get navigation position.
 *
 * @param nav Navigation element.
 * @returns Navigation position (left, right or fullwidth).
 */
function getNavigationMode(nav) {
    const box = nav.getBoundingClientRect();
    const distanceLeft = Math.round(box.left - document.body.scrollLeft);
    const distanceRight = Math.round(window.innerWidth - distanceLeft - box.width);
    if (distanceLeft <= 0 && distanceRight > 0) {
        return "left";
    }
    else if (distanceLeft > 0 && distanceRight <= 0) {
        return "right";
    }
    return "fullwidth";
}
/**
 * Close the navigation with swipe gestures within the navigation and its background.
 */
function swipeToClose(nav, dispatch) {
    const backdrop = document.querySelector(".mobile-navigation-backdrop");
    const elements = [nav, backdrop];
    let touchStartX = 0;
    let touchStartY = 0;
    let touchMoveEndX = 0;
    let touchMoveEndY = 0;
    let isClosing = false;
    elements.forEach((el) => {
        el.addEventListener("touchstart", 
        // @ts-expect-error Problem with TouchEvent compatibility with EventListener type
        (e) => {
            isClosing = false;
            touchStartX = e.touches[0].clientX;
            touchStartY = e.touches[0].clientY;
        }, {
            passive: true,
        });
        el.addEventListener("touchmove", 
        // @ts-expect-error Problem with TouchEvent compatibility with EventListener type
        (e) => {
            touchMoveEndX = e.touches[0].clientX;
            touchMoveEndY = e.touches[0].clientY;
            const threshold = 20; // prevent accidental closing with gentle gestures
            const xDiff = touchStartX - touchMoveEndX;
            const yDiff = touchStartY - touchMoveEndY;
            if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) >= threshold) {
                // horizontal swipe
                const navigationPosition = getNavigationMode(nav);
                if (xDiff > 0 && navigationPosition === "left") {
                    // left swipe and left navigation
                    if (!isClosing) {
                        dispatch({ type: "CLOSE_NAV" });
                    }
                    isClosing = true;
                }
                else if (xDiff < 0 && navigationPosition === "right") {
                    // right swipe and right nawigation
                    if (!isClosing) {
                        dispatch({ type: "CLOSE_NAV" });
                    }
                    isClosing = true;
                }
            }
        }, {
            passive: true,
        });
    });
}
export default {
    init: (state = {}) => [
        Object.assign({}, {
            open: false,
            pathname: "/",
        }, state),
        Cmd.of(initNavigationState(state)),
    ],
    update: (state, action) => {
        switch (action.type) {
            case "ACTIVATE_ITEM": {
                const newState = Object.assign({}, state, {
                    pathname: action.target,
                });
                return [newState, Cmd.none];
            }
            case "TOGGLE_NAV": {
                const newState = Object.assign({}, state, {
                    open: !state.open,
                });
                return [newState, Cmd.none];
            }
            case "CLOSE_NAV": {
                const newState = Object.assign({}, state, {
                    open: false,
                });
                return [newState, Cmd.none];
            }
            default:
                return [state, Cmd.none];
        }
    },
    view: (selector, state, dispatch) => {
        const navToggles = document.querySelectorAll(".js-navigation-toggle");
        const navs = document.querySelectorAll(selector);
        if (state.open) {
            navToggles.forEach((toggle) => {
                toggle.classList.add("is-active");
            });
            responsiveNav.classList.add("is-open");
            responsiveNav.setAttribute("aria-expanded", "true");
            document.body.classList.add("l-navigation-open");
        }
        else {
            navToggles.forEach((toggle) => {
                toggle.classList.remove("is-active");
            });
            responsiveNav.classList.remove("is-open");
            responsiveNav.setAttribute("aria-expanded", "false");
            document.body.classList.remove("l-navigation-open");
        }
        onInit(navs[0], () => {
            navs.forEach((item) => {
                const nav = item;
                const options = Object.assign({}, defaultOptions, nav.dataset.navigation ? JSON.parse(nav.dataset.navigation) : null);
                markActiveNavLinks(nav, state, options);
                if (nav.classList.contains("mobile-navigation")) {
                    swipeToClose(nav, dispatch);
                }
            });
            navToggles.forEach((toggle) => {
                toggle.addEventListener("click", (e) => {
                    dispatch({ type: "TOGGLE_NAV" });
                    e.preventDefault();
                });
            });
            document.addEventListener("to-element.restaumatic.scroll", () => {
                dispatch({ type: "CLOSE_NAV" });
            });
        });
    },
};
