export function groupBy(sameGroup, xs) {
    let group = [];
    const result = [];
    for (const x of xs) {
        if (group.length > 0 && !sameGroup(group[0], x)) {
            result.push(group);
            group = [];
        }
        group.push(x);
    }
    if (group.length > 0) {
        result.push(group);
    }
    return result;
}
