import dayjs from "restaumatic-client/src/core/Dayjs";
/**
 * NOTICE: It uses `dayjs` to stick to the one "time related" library.
 * Is is also used in Restaumatic Client. Before this change it was `date-fns` lib.
 */
const getDateTime = (timeZone) => {
    return dayjs().tz(timeZone).toDate();
};
const parseDateTime = (dateTime, format) => {
    return dayjs(dateTime, format).toDate();
};
const daysToSeconds = (days) => {
    return days * 86400;
};
const timeToSeconds = (timeStr) => {
    const time = timeStr instanceof Date ? timeStr : parseDateTime(timeStr, "HH:mm:ss");
    return time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
};
const secondsToTime = (seconds) => {
    const d = new Date(1970, 1, 1);
    d.setSeconds(seconds);
    return d;
};
const shiftDays = (dateTime, shift) => {
    return dayjs(dateTime).add(shift, "day").toDate();
};
const timeToString = (time, format = "HH:mm:ss") => {
    return dayjs(time).format(format);
};
const isDayBetween = (now, start, end) => {
    return (toNumericDate(start) <= toNumericDate(now) &&
        toNumericDate(now) <= toNumericDate(end));
};
const toNumericDate = (date) => {
    return date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();
};
// nd=0 ... sb=6
const dayIndex = (date) => {
    return date.getDay();
};
// pn=1 ... nd=7
const dayIndexIso = (date) => {
    const d = date.getDay();
    return d === 0 ? 7 : d;
};
export default {
    getDateTime,
    parseDateTime,
    daysToSeconds,
    timeToSeconds,
    secondsToTime,
    shiftDays,
    timeToString,
    isDayBetween,
    dayIndex,
    dayIndexIso,
};
