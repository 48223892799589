import { useEffect } from "preact/hooks";
/**
 * Handle scrollIfAbove method with useEffect to use it in preact codebase.
 *
 * @param condition {Boolean} Dependency variable. Change to "true" triggers scroll.
 * @param scrollToElementId {String} ID of element we want to scroll to.
 * @param onScroll {Function} Optional function to call after scrollIfAbove function is executed.
 */
export default function (condition, scrollToElementId, onScroll) {
    useEffect(() => {
        if (!condition)
            return;
        const scrollToElement = document.getElementById(scrollToElementId);
        if (scrollToElement instanceof HTMLElement) {
            const { scrollIfAbove } = require("../utils/Scroll");
            scrollIfAbove(scrollToElement);
            onScroll && onScroll();
        }
    }, [condition]);
}
