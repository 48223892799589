import Notify from "./Notify";
import Navigation from "./Navigation";
import forwardTo from "../utils/forwardTo";
import Cmd from "../utils/Cmd";
import { getCurrentRestaurantSlug } from "../utils/CurrentRestaurant";
import RestaurantHours from "../components/RestaurantHours";
const { assign } = Object;
/**
 * Initiate state for App component.
 *
 * @param {State} state Object containing initial state.
 * @returns {*[]}
 */
function init(state) {
    const [notifyState, notifyCmd] = Notify.init(state.notify || []);
    const [navigationState, navigationCmd] = Navigation.init(state.navigation || {});
    const [restaurantHoursState, restaurantHoursCmd] = RestaurantHours.init(state.restaurantHours || {});
    return [
        assign({}, state, {
            notify: notifyState,
            navigation: navigationState,
            restaurantHours: restaurantHoursState,
        }),
        Cmd.batch(notifyCmd.tag("NOTIFY_ACTION"), navigationCmd.tag("NAVIGATION_ACTION"), restaurantHoursCmd.tag("RESTAURANT_HOURS_ACTION"), Cmd.of((dispatch) => {
            document.addEventListener("change-restaurant.restaumatic", () => {
                // Should cause a rerender of RestaurantHours
                dispatch({ type: "NOOP" });
            });
        })),
    ];
}
/**
 * Update component state using proper child update method.
 *
 * @returns {*[]}
 */
function update(state, action) {
    switch (action.type) {
        case "NOTIFY_ACTION":
            const [notifyState, notifyCmd] = Notify.update(state.notify, action.payload);
            return [
                Object.assign({}, state, { notify: notifyState }),
                notifyCmd.tag("NOTIFY_ACTION"),
            ];
        case "NAVIGATION_ACTION":
            const [navigationState, navigationCmd] = Navigation.update(state.navigation, action.payload);
            return [
                Object.assign({}, state, { navigation: navigationState }),
                navigationCmd.tag("NAVIGATION_ACTION"),
            ];
        case "RESTAURANT_HOURS_ACTION":
            const [restaurantHoursState, restaurantHoursCmd] = RestaurantHours.update(state.restaurantHours, action.payload);
            return [
                assign({}, state, { restaurantHours: restaurantHoursState }),
                restaurantHoursCmd.tag("RESTAURANT_HOURS_ACTION"),
            ];
        default:
            return [state, Cmd.none];
    }
}
/**
 * Update the view.
 */
function view(state, dispatch) {
    if (document.querySelector(".js-notify")) {
        Notify.view(".js-notify", state.notify, forwardTo(dispatch, "NOTIFY_ACTION"));
    }
    if (document.querySelector(".js-navigation")) {
        Navigation.view(".js-navigation", state.navigation, forwardTo(dispatch, "NAVIGATION_ACTION"));
    }
    const currentSlug = getCurrentRestaurantSlug();
    document
        .querySelectorAll(`.js-restaurant-hours[data-restaurant-slug="${currentSlug}"]`)
        .forEach((restaurantHourEl) => {
        RestaurantHours.view(restaurantHourEl, state.restaurantHours, forwardTo(dispatch, "RESTAURANT_HOURS_ACTION"));
    });
}
export default {
    init,
    update,
    view,
};
