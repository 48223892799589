import GroupToggleNav from "./GroupToggleNav";
import GroupToggleContent from "./GroupToggleContent";
import onInit from "../utils/onInit";
import { scrollToElement, scrollIfAbove } from "../utils/Scroll";
import forwardTo from "../utils/forwardTo";
import Cmd from "../utils/Cmd";
import Store from "../services/Store";
const hashUrlFormat = "#";
const toggleGroupLinkSelector = ".js-toggle-group-link";
// Define a prefix for ID attribute to prevent default browser jump at page load.
const anchorPrefix = "";
const menuSectionAnchor = `${anchorPrefix}menu`;
const isOnePageMode = () => !!document.getElementById(`${menuSectionAnchor}`);
/**
 * Update ID attribute of GroupToggle items to prevent default jump of the browser at page load.
 *
 * This function has to be called before GroupToggle init.
 */
function updateIdAttr() {
    document.querySelectorAll(".js-group-item").forEach((el) => {
        const item = el.getAttribute("id") || "";
        if (!isCorrectAnchorFormat(item)) {
            el.setAttribute("id", getAnchorFromItem(item));
        }
    });
}
/**
 * Go to menu section in case of one page layout when menu group hash is present in the URL.
 *
 * Note that "list-all" mode is a special case and it's handled by `scrollToGroup()`, therefore it doesn't require separate initialization.
 */
function initMenuSectionStartup(state) {
    if (state.mode !== "list-all" &&
        location.hash.indexOf("#menu-") !== -1 &&
        isOnePageMode()) {
        const el = document.getElementById(`${menuSectionAnchor}`);
        if (!(el instanceof HTMLElement))
            return;
        scrollToElement(el);
    }
}
/**
 * Check ID attribute for correct anchor.
 */
const isCorrectAnchorFormat = (anchor = "") => anchor.substring(0, anchorPrefix.length) === anchorPrefix;
/**
 * Get GroupToggle item form hash.
 *
 * @returns {string} Original ID attribute without anchor prefix (GroupToggle item).
 */
const getItemFromHash = (hash = location.hash) => getItemFromAnchor(hash.substring(hashUrlFormat.length));
/**
 * Get full ID attribute of GroupToggle item with anchor prefix.
 *
 * @returns {string} ID attribute with anchor prefix.
 */
const getAnchorFromItem = (item = "") => isCorrectAnchorFormat(item) ? item : anchorPrefix + item;
/**
 * Get original item name from ID attribute of GroupToggle item.
 *
 * @param {string} anchor ID attribute with anchor.
 * @returns {string} Original ID attribute without anchor prefix (GroupToggle item).
 */
const getItemFromAnchor = (anchor = "") => isCorrectAnchorFormat(anchor)
    ? anchor.substring(anchorPrefix.length)
    : anchor;
/**
 * Get Element object with correct anchor.
 *
 * @param {string} item GroupToggle item
 */
const getElementFromItem = (item = "") => document.getElementById(getAnchorFromItem(item));
/**
 * Check if item isn't set.
 */
const isItemNotSet = ({ currentItem, previousItem }) => currentItem === "" && previousItem !== "";
/**
 * Toggle item.
 */
const toggleItem = (newItem, currentItem) => newItem === currentItem ? "" : newItem;
/**
 * Check if element with specific id is an item of GroupToggle.
 */
function isGroupToggleItem(elementId) {
    const el = document.getElementById(getAnchorFromItem(elementId));
    return !!el && el.classList.contains("js-group-item");
}
/**
 * Initiate state of GroupToggle.
 *
 * @param state
 * @returns {function(*, *)}
 */
function initGroupToggleState(state) {
    return (dispatch, getState) => {
        const group = document.querySelector(".js-group");
        if (!(group instanceof HTMLElement))
            return;
        const mode = Skubacz.Device.screen.isMobileSize()
            ? group.getAttribute("data-mode-mobile") || "accordion"
            : state.mode || group.getAttribute("data-mode") || "tab";
        let currentItem = state.currentItem;
        if (isGroupToggleItem(getItemFromHash())) {
            currentItem = getItemFromHash();
        }
        else if (currentItem === "" || !isGroupToggleItem(currentItem)) {
            const accordionInitState = group.getAttribute("data-accordion-init");
            if (mode === "accordion" && accordionInitState === "close") {
                currentItem = "";
            }
            else {
                const activeGroupItem = group.querySelector(".js-group-item.is-active");
                if (activeGroupItem) {
                    currentItem = getItemFromAnchor(activeGroupItem.getAttribute("id") || "");
                }
                else {
                    const firstGroupItem = group.querySelector(".js-group-item");
                    if (!firstGroupItem)
                        return;
                    currentItem = getItemFromAnchor(firstGroupItem.getAttribute("id") || "");
                }
            }
        }
        prepareGroupTarget(toggleGroupLinkSelector);
        dispatch({ type: "CHANGE_MODE", mode: mode, effectType: "init" });
        dispatch({ type: "CHANGE_ITEM", target: currentItem, effectType: "init" });
    };
}
/**
 * Scroll to target group.
 */
function scrollToGroup(mode, currentItem) {
    // Hash check ensures we won't override native anchor which is not part of the menu.
    if (!location.hash ||
        !isGroupToggleItem(getItemFromHash(location.hash)) ||
        !isGroupToggleItem(currentItem)) {
        return;
    }
    const el = getElementFromItem(currentItem);
    if (!(el instanceof HTMLElement))
        return;
    if (mode === "list-all") {
        scrollToElement(el);
    }
    else {
        scrollIfAbove(el);
    }
}
/**
 * Update browser history with selected item.
 */
function updateHistory(currentItem) {
    if (typeof history.pushState === "function" &&
        currentItem !== getItemFromHash() &&
        currentItem !== "") {
        history.pushState(null, "", hashUrlFormat + currentItem);
    }
}
/**
 * Trigger GroupToggle events.
 */
function triggerEvents(currentItem, previousItem, mode) {
    const currentItemEl = getElementFromItem(currentItem);
    const previousItemEl = getElementFromItem(previousItem);
    const hiddenEvent = new CustomEvent("hidden.restaumatic.group-toggle", {
        detail: {
            el: previousItemEl,
            relatedTarget: currentItemEl,
            mode: mode,
        },
    });
    const shownEvent = new CustomEvent("shown.restaumatic.group-toggle", {
        detail: {
            el: currentItemEl,
            relatedTarget: previousItemEl,
            mode: mode,
        },
    });
    if (currentItemEl) {
        document.dispatchEvent(shownEvent);
        if (previousItemEl) {
            document.dispatchEvent(hiddenEvent);
        }
    }
    else if (previousItemEl) {
        document.dispatchEvent(hiddenEvent);
    }
}
/**
 * Apply item side effects.
 */
function applyItemSideEffects(state, effectType = "default") {
    return (dispatch, getState) => {
        if (effectType !== "init") {
            updateHistory(state.currentItem);
        }
        scrollToGroup(state.mode, state.currentItem);
        triggerEvents(state.currentItem, state.previousItem, state.mode);
    };
}
/**
 * Check currentItem and update it if necessary.
 */
function changeItem(state, target) {
    return (dispatch, getState) => {
        if (state.currentItem !== target) {
            dispatch({ type: "CHANGE_ITEM", target: target });
        }
    };
}
/**
 * Update state of the application.
 *
 * @returns {*[]} An array with new state and Cmd object.
 */
function updateNormal(state, action) {
    switch (action.type) {
        case "CHANGE_MODE": {
            const newState = Object.assign({}, state, { mode: action.mode });
            const cmd = newState.currentItem === "" &&
                newState.mode !== "accordion" &&
                action.effectType !== "init"
                ? Cmd.of(initGroupToggleState(newState))
                : Cmd.none;
            return [newState, cmd];
        }
        case "CHANGE_ITEM": {
            const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.update(state.currentItem, action);
            const [groupToggleContentState, groupToggleContentCmd] = GroupToggleContent.update(state.currentItem, action);
            const newState = Object.assign({}, state, {
                previousItem: state.currentItem,
                currentItem: groupToggleNavState || groupToggleContentState,
            });
            return [
                newState,
                Cmd.batch(Cmd.of(applyItemSideEffects(newState, action.effectType)), groupToggleNavCmd.tag("GROUP_TOGGLE_NAV_ACTION"), groupToggleContentCmd.tag("GROUP_TOGGLE_CONTENT_ACTION")),
            ];
        }
        case "GROUP_TOOGLE_NAV_ACTION": {
            return updateGroupToggleNav(state, action);
        }
        case "GROUP_TOOGLE_CONTENT_ACTION": {
            return updateGroupToggleContent(state, action);
        }
        default:
            return [state, Cmd.none];
    }
}
/**
 * Update state of GroupToggleNav component.
 *
 * @returns {[*,*]}
 */
function updateGroupToggleNav(state, action) {
    const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.update(state.currentItem, action.payload);
    return [
        Object.assign({}, state, { currentItem: groupToggleNavState }),
        Cmd.batch(Cmd.of(changeItem(state, groupToggleNavState)), groupToggleNavCmd.tag("GROUP_TOOGLE_NAV_ACTION")),
    ];
}
/**
 * Update state of GroupToggleContent component.
 *
 * @returns {[*,*]}
 */
function updateGroupToggleContent(state, action) {
    const [groupToggleContentState, groupToggleContentCmd] = GroupToggleContent.update(state.currentItem, action.payload);
    return [
        Object.assign({}, state, { currentItem: groupToggleContentState }),
        Cmd.batch(Cmd.of(changeItem(state, groupToggleContentState)), groupToggleContentCmd.tag("GROUP_TOOGLE_CONTENT_ACTION")),
    ];
}
/**
 * Update state of the application in case of accordion mode.
 *
 * @param state
 * @param action
 * @returns {*[]} An array with new state and Cmd object.
 */
function updateAccordion(state, action) {
    switch (action.type) {
        case "CHANGE_MODE": {
            if (isItemNotSet(state)) {
                const newState = Object.assign({}, state, {
                    mode: action.mode,
                    currentItem: state.previousItem,
                });
                return [
                    newState,
                    Cmd.of(applyItemSideEffects(newState, action.effectType)),
                ];
            }
            else {
                return updateNormal(state, action);
            }
        }
        case "CHANGE_ITEM":
            return updateNormal(state, Object.assign({}, action, {
                target: toggleItem(action.target, state.currentItem),
            }));
        case "GROUP_TOOGLE_NAV_ACTION": {
            return updateNormal(state, Object.assign({}, action.payload, {
                target: toggleItem(action.payload.target, state.currentItem),
            }));
        }
        case "GROUP_TOOGLE_CONTENT_ACTION": {
            return updateNormal(state, Object.assign({}, action.payload, {
                target: toggleItem(action.payload.target, state.currentItem),
            }));
        }
        default:
            return [state, Cmd.none];
    }
}
/**
 * Prepare GroupToggle for external components.
 *
 * Add `data-target` attribute with correct anchor.
 *
 * @param selector
 */
function prepareGroupTarget(selector) {
    document.querySelectorAll(selector).forEach((el) => {
        el.setAttribute("data-target", "#" + getAnchorFromItem(el.getAttribute("href").substring(1)));
    });
}
export function init() {
    const groupToggleStore = Store.create(GroupToggle.init({}), GroupToggle.update);
    groupToggleStore.subscribe(() => {
        const group = document.querySelector(".js-group");
        if (group) {
            GroupToggle.view(group, groupToggleStore.getState(), groupToggleStore.dispatch);
        }
    });
    groupToggleStore.dispatch({ type: "INIT" });
}
/**
 * Group utils for external use.
 */
export const utils = {
    updateIdAttr,
    getItemFromHash,
    getItemFromAnchor,
    isGroupToggleItem,
};
/**
 * Elm-JQuery component. Not exported, use the exported init() function to run it with its own store.
 */
const GroupToggle = {
    /**
     * Initiate GroupToggle state.
     *
     * @param {object} state Object containing initial state.
     * @param {string} state.mode Mode of the GroupToggle component. It can be `tab`, `accordion` or `list-all`.
     * @param {array} state.previousItem Previous item.
     * @param {string} state.currentItem Currently selected item.
     */
    init: (state = {}) => {
        const [groupToggleNavState, groupToggleNavCmd] = GroupToggleNav.init();
        const [groupToggleContentState, groupToggleContentCmd] = GroupToggleContent.init();
        state = Object.assign({
            mode: "",
            previousItem: "",
            currentItem: groupToggleNavState || groupToggleContentState,
        }, state);
        return [
            state,
            Cmd.batch(Cmd.of(initGroupToggleState(state)), groupToggleNavCmd.tag("GROUP_TOGGLE_NAV_ACTION"), groupToggleContentCmd.tag("GROUP_TOGGLE_CONTENT_ACTION")),
        ];
    },
    /**
     * Update state of the component.
     *
     * @param state
     * @param action
     * @returns {*[]}
     */
    update: (state, action) => {
        if (state.mode === "accordion") {
            return updateAccordion(state, action);
        }
        else {
            return updateNormal(state, action);
        }
    },
    /**
     * Update the view.
     *
     * @param element
     * @param state
     * @param dispatch
     */
    view: (element, state, dispatch) => {
        if (document.querySelector(".js-toggle-group-link")) {
            GroupToggleNav.view(document.querySelectorAll(".js-toggle-group-link"), state.currentItem, forwardTo(dispatch, "GROUP_TOOGLE_NAV_ACTION"));
        }
        if (element.querySelector(".js-group-item")) {
            GroupToggleContent.view(element.querySelectorAll(".js-group-item"), state.currentItem, forwardTo(dispatch, "GROUP_TOOGLE_CONTENT_ACTION"));
        }
        onInit(element, () => {
            const mql = window.matchMedia("(min-width: " + Skubacz.Device.breakpoints.gridFloatBreakpoint + "px)");
            if (mql.onchange !== undefined) {
                mql.addEventListener("change", (mql) => {
                    if (mql.matches) {
                        dispatch({
                            type: "CHANGE_MODE",
                            mode: element.getAttribute("data-mode") || "tab",
                        });
                    }
                    else {
                        dispatch({
                            type: "CHANGE_MODE",
                            mode: element.getAttribute("data-mode-mobile") || "accordion",
                        });
                    }
                });
            }
            // If statement needed for correct handling of manual url hashchange, or browser's native back/forward  navigation when `menu_group_display_mode` = `active-only`.
            window.addEventListener("hashchange", () => {
                // https://restaumatic.atlassian.net/browse/RS-6021
                if (Skubacz.configuration.menu.skip_group_scroll) {
                    Skubacz.configuration.menu.skip_group_scroll = false;
                    return;
                }
                const item = getItemFromHash();
                if (isGroupToggleItem(item)) {
                    dispatch({ type: "CHANGE_ITEM", target: item });
                }
            });
            initMenuSectionStartup(state);
        });
    },
};
