function Cmd(fn) {
  if (!fn) {
    throw new Error("Computation neeeds to be provided");
  }
  if (!(this instanceof Cmd)) {
    return new Cmd(fn);
  }
  this.fn = fn;
}

Cmd.prototype.tag = function (tag) {
  const c = this.fn;
  return new Cmd((dispatch, getState) => {
    const newDispatch = (action) => dispatch({ type: tag, payload: action });
    newDispatch.toString = () => "dispatch_#{tag}()";
    return c.apply(this, [newDispatch, getState]);
  });
};

Cmd.prototype.unsafePerform = function (dispatch, getState) {
  return this.fn.apply(this, [dispatch, getState]);
};

Cmd.batch = (...cmds) => {
  cmds.forEach((c) => {
    if (!(c instanceof Cmd)) {
      throw new Error("You can only provide Cmd's to batch");
    }
  });
  return new Cmd((dispatch, getState) => {
    cmds.forEach((c) => c.unsafePerform(dispatch, getState));
  });
};

Cmd.prototype.of = function (fn) {
  return new Cmd(fn);
};

Cmd.of = Cmd.prototype.of;

Cmd.none = Cmd.of(function () {});

export default Cmd;
