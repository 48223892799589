import { Tracking } from "restaumatic-client";
import copyToClipboard from "./copyToClipboard";

const googleReviewBaseUrl =
  "https://search.google.com/local/writereview?placeid=";
const reviewOkContent = document.querySelectorAll(".js-ok-review-content");
const copyBtn = document.getElementById("review-copy");

// Form elements which providing data
const reviewFormContent = document.querySelector(
  'textarea[name="review[content]"]'
);
const reviewFormAuthor = document.querySelector('input[name="review[author]"]');
const reviewFormGooglePlaceId = document.querySelector(
  'input[name="review[google_place_id]"]'
);

// Elements which receiving data
const reviewWrapperEl = document.getElementById("review-wrapper");
const reviewContentEl = document.getElementById("review-comment");
const reviewRatingEl = document.getElementById("review-rating");
const reviewAuthorEl = document.getElementById("review-author");
const reviewGoogleLinkEl = document.getElementById("review-google-link");

function googleReviewRequestInit() {
  const viewName = Skubacz.configuration.view_name;
  if (viewName !== "review" && viewName !== "reviews") return;

  function handleVisibility(setSessionStorage = false) {
    const radioChecked = document.querySelector(
      'input[name="review[restaurant_rating]"]:checked'
    );
    const ratingThreshold = 4;
    const restaurantRatingValue = radioChecked
      ? parseInt(radioChecked.value)
      : parseInt(sessionStorage.getItem("reviewRating")) >= ratingThreshold
      ? ratingThreshold
      : 0;

    if (reviewOkContent.length) {
      const reviewGooglePlaceId = sessionStorage.getItem("reviewGooglePlaceId");

      if (restaurantRatingValue >= ratingThreshold && reviewGooglePlaceId) {
        reviewOkContent.forEach((el) => {
          el.style.display = "block";
        });
        Tracking.trackEvent({ tag: "ReviewGoogleLinkShown" });
      }
    }

    if (setSessionStorage) {
      sessionStorage.setItem("reviewComment", reviewFormContent.value);
      sessionStorage.setItem("reviewRating", restaurantRatingValue);
      sessionStorage.setItem("reviewAuthor", reviewFormAuthor.value);
      sessionStorage.setItem(
        "reviewGooglePlaceId",
        reviewFormGooglePlaceId.value
      );
    }
  }

  const radios = document.querySelectorAll(
    'input[name="review[restaurant_rating]"]'
  );
  if (radios.length) {
    radios.forEach((radio) =>
      radio.addEventListener("change", () => handleVisibility(false))
    );
  }

  // Init in new review forms
  const reviewForm = document.querySelector('[action="/reviews"]');
  if (reviewForm) {
    reviewForm.addEventListener("submit", () => handleVisibility(true));
  }

  // Init static pages (e.g. reviews view).
  if (viewName !== "review") {
    const reviewComment = sessionStorage.getItem("reviewComment");
    const reviewRating = sessionStorage.getItem("reviewRating");
    const reviewAuthor = sessionStorage.getItem("reviewAuthor");
    const reviewGooglePlaceId = sessionStorage.getItem("reviewGooglePlaceId");

    if (reviewRating && reviewComment) {
      reviewWrapperEl.style.display = "block";
      reviewContentEl.innerText = reviewComment;
      reviewAuthorEl.textContent = reviewAuthor;
      handleVisibility(false);

      if (reviewRatingEl && reviewRating) {
        renderRatingStars(reviewRatingEl, reviewRating);
      }

      if (reviewGoogleLinkEl && reviewGooglePlaceId) {
        reviewGoogleLinkEl.setAttribute(
          "href",
          `${googleReviewBaseUrl}${reviewGooglePlaceId}`
        );
      }

      // Copy review text from submitted review to clipboard
      if (reviewComment && copyBtn) {
        copyBtn.addEventListener("click", () => {
          copyToClipboard(reviewComment, () => {
            copyBtn.classList.add("is-copied");
            setTimeout(() => copyBtn.classList.remove("is-copied"), 5000);
          });
        });
      }
    }
  }
}

/**
 * Render rating stars HTML element.
 *
 * @param {HTMLElement} container HTML DOM parent of stars.
 * @param {Number} rating Rating of review.
 */
function renderRatingStars(container, rating) {
  const starsHtmlEl = document.createElement("span");
  starsHtmlEl.classList.add("stars");
  starsHtmlEl.setAttribute("title", `${rating}/5`);

  for (let i = 0; i < 5; i++) {
    let starIcon;
    if (rating > i) {
      starIcon = `<i class="icon-star"></i>`;
    } else {
      starIcon = `<i class="icon-star-empty"></i>`;
    }
    starsHtmlEl.insertAdjacentHTML("beforeend", starIcon);
  }
  container.appendChild(starsHtmlEl);
}

/**
 * Display the appropriate number of stars according to the selected radio.
 *
 * @param {NodeList} radios All radios in a group.
 * @param {HTMLElement} selected Selected radio.
 */
function selectRate(radios, selected) {
  radios.forEach((radio) => {
    const label = radio.parentNode;

    if (radio.value <= selected.value) {
      label.classList.remove("icon-star-empty");
      label.classList.add("icon-star");
    } else {
      label.classList.remove("icon-star");
      label.classList.add("icon-star-empty");
    }
  });
}

/**
 * Stars rating for review component with native radio inputs.
 */
export function init() {
  const ratingGroups = document.querySelectorAll(".js-rating");

  googleReviewRequestInit();
  ratingGroups.forEach((group) => {
    const radios = group.querySelectorAll('input[type="radio"]');

    radios.forEach((radio) =>
      radio.addEventListener("change", () => selectRate(radios, radio), false)
    );
  });
}
