const loops = [];

const addToLoop = (func, interval) => {
  const l = loops.find((x) => x.interval == interval);
  if (typeof l === "undefined") {
    return false;
  } else {
    l.funcs.push(func);
    func();
    return true;
  }
};

const createLoop = (func, interval) => {
  const funcs = [func];
  const loop = () => {
    funcs.forEach((x) => x());
    setTimeout(loop, 1000 * interval);
  };
  loop();
  loops.push({ loop, funcs, interval });
};

export default (func, interval = 60) =>
  addToLoop(func, interval) || createLoop(func, interval);
