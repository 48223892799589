export default function testLocalStorage() {
  try {
    const key = "test";
    const expected = "value";
    localStorage.setItem(key, expected);
    const actual = localStorage.getItem(key);
    if (actual !== "value") {
      console.log(
        "Local storage doesn't work, expected value: " +
          expected +
          ", actual: " +
          actual
      );
    } else {
      console.log("Local storage seems to work");
    }
  } catch (e) {
    console.log("Local storage doesn't work, exception: " + e);
  }
}
