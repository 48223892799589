import onInit from "../utils/onInit";
import * as GroupToggle from "../components/GroupToggle";
import Cmd from "../utils/Cmd";
function target(item) {
    return (GroupToggle.utils.getItemFromHash(item.getAttribute("href") || "") ||
        item.getAttribute("data-target") ||
        "");
}
export default {
    /**
     * Initiate GroupToggleNav state.
     *
     * @param {string} state Current item.
     */
    init: (state = "") => [state, Cmd.none],
    update: (state, action) => {
        switch (action.type) {
            case "CHANGE_ITEM":
                return [action.target, Cmd.none];
        }
        return [state, Cmd.none];
    },
    view: (elements, state, dispatch) => {
        elements.forEach((link) => {
            if (target(link) === state) {
                link.classList.add("is-active");
            }
            else {
                link.classList.remove("is-active");
            }
        });
        onInit(elements[0], () => {
            elements.forEach((el) => {
                el.addEventListener("click", (e) => {
                    const t = target(e.currentTarget);
                    if (GroupToggle.utils.isGroupToggleItem(t)) {
                        dispatch({ type: "CHANGE_ITEM", target: t });
                        e.preventDefault();
                    }
                });
            });
        });
    },
};
